import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstRegistration() {
  // /* Slider */
  // const regSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `GST Registration Service`,
  //     content: `India's Best GST Registration Service`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online GST Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online GST Registration`,
  //     content: `Best GST Registration Service in Hosur`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online GST Registration Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online GST Registration Service`,
  //     content: `Best GST Registration Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online GST Registration Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `1499`,
    f_price: `3999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const regAboutData = {
    header: `GOODS Service Tax(GST)`,
    sub_title: `Online GST Filing for the personal and business related services `,
    content_paragraph: [
      `Apply for GST Registration Online in India with TODAYFILINGS. According to the
      GST legislative process, every business activity or organization with an
      annual turnover over Rs 40 lakhs must go through the GST process/procedure
      in India as a separate taxable provision.`,

      // `According to GST jurisdiction, any business operations with an annual turnover
      // of more than Rs 40 lakhs must register as a separate taxable provision. This is referred
      // to as GST registration. In India, obtaining GST registration online takes approximately 2-6
      // working days.`,
      // `There are different GST registration categories, including regular, occasional,
      //  non-resident, and eCommerce operators.`,
    ],
    // price: PriceCompData
  };

  /* Scroll Nav Data */
  const regScrollId = [
    {
      id: `#GST`,
      heading: `GST Benefits
      `,
    },
    {
      id: `#eligibility`,
      heading: `Eligibility For GST`,
    },
    {
      id: `#role`,
      heading: `Role In GST Filing`,
    },
    {
      id: `#document`,
      heading: `Document`,
    },
  ];

  /* Img Content Component Data */
  const regIcData = {
    id: 'GST',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Benefits Of GST Registration',
    image: '/imgs/gifs/Tax-Image.gif',
    alt_tag: 'MsMe Registration Procedure',
    points: [
      {
        content: ` GST removes the cascading effect of tax`,
        icon: true,
      },
      {
        content: `Defined treatment for E-commerce operators`,
        icon: true,
      },
      {
        content: `Improved efficiency`,
        icon: true,
      },
      {
        content: `Composition scheme for small businesses`,
        icon: true,
      },
      {
        content: `Number of compliance is less`,
        icon: true,
      },
      {
        content: `Unorganized sectors is regulated under GST`,
        icon: true,
      },
      {
        content: `Higher threshold for registration`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const regCfData = {
    id: 'eligibility',
    // mt_div: "mt-5",
    // mt_img: "mt-5",
    header: 'Eligibility For GST Registration',
    content: ``,
    body: [
      {
        points: `In order to be eligible for registration under this act in the state or
        union territory from which he makes taxable supplies of goods or services or both,
         `,
        icon: true,
      },
      {
        points: `Person who is engaged in the supply of services and whose aggregate turnover exceeds
          Rs. 10 lakhs for the States of Manipur, Mizoram, Nagaland, and Tripura and exceeds Rs.
           20 lakhs for the rest of the States;`,
        icon: true,
      },
      {
        points: `Every person who engages in the supply of goods and whose aggregate turnover in a
         fiscal year exceeds Rs. 20 lakh for the States of Arunachal Pradesh, Manipur, Meghalaya, Mizoram,
           Nagaland, Puducherry, Sikkim, Telangana, Tripura, and Uttarakhand.`,
        icon: true,
      },
      {
        points: `For the rest  of the States Rs. 40 lakhs  shall be liable to be registered under this
        act in the state or union territory,from which he makes of service tax businesses registration.`,
        icon: true,
      },
      {
        points: `To be eligible for tax exemption on the export of services outside of India, companies that are
         involved in the export of services must also complete the GST migration process and receive a GST
         Registration number.Regardless of turnover, GST Registration is required for interstate sales.`,
        icon: true,
      },
      {
        points: `If the company conducts any small or big business organizations without having registered for GST, it will be
         considered a criminal offence, and both the company and the owner could face severe fines
         and other legal issues about their business GST.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const regCiData = {
    id: 'document',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Documents For GST Registration Online In India',
    paragraph: ``,
    points: [
      {
        content: `Depending on the type of business, different documents are required
        for GST Registration Online in India.`,
        icon: true,
      },
      {
        content: `The required documents are listed below:`,
        icon: true,
      },
      {
        content: ` Proprietorship`,
        icon: true,
      },
      {
        content: ` LLP Or Limited Liability Partnership`,
        icon: true,
      },
      {
        content: ` For Private Limited Company`,
        icon: true,
      },
      {
        content: `For Address Proof Of Directorship`,
        icon: true,
      },
      {
        content: `For Identity Proof Of GST Registration`,
        icon: true,
      },
      {
        content: `Certificate of company Incorporation`,
        icon: true,
      },
      {
        content: `Identity Proof Of Directorship`,
        icon: true,
      },
    ],
    image: '/imgs/registration/ipr/td8.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* Slider */
  const imgSlider = [
    '/imgs/business-plan.png',
    '/imgs/features.png',
    '/imgs/registration/msme/msme-reg.png',
  ];

  /*  Slider Content Component Data */
  const regSCData = {
    id: 'role',
    background: [],
    mt_div: 'mt-3',
    header: 'Our Role In GST Filing Services',
    content: [
      {
        points: `Evaluation Of Key Parameters For The Fiscal Impact Of GST On Business And Product Pricing Involves.`,
        icon: true,
      },
      {
        points: ` Provide guidance on reorganizing operations to maximize tax efficiency.`,
        icon: true,
      },
      {
        points: ` Advice Regarding the Effects of the GST Laws' Anti-Profiteering Provisions.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /* FaQ data */
  var GSTRegFAQ = [
    {
      header: 'What is GST?',
      body: [
        {
          content: ` GST, or Goods and Services Tax, is an indirect tax imposed on the
          supply of goods and services. It is a multi-stage,
          destination-oriented tax imposed on every value addition, replacing
          multiple indirect taxes, including VAT, excise duty, service taxes,
          etc.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'How to enrol for GST?',
      body: [
        {
          content: ` You can enrol for GST via the common portal of the Goods and
          Services Tax. ClearTax can also help you with your enrollment.`,
          icon: false,
        },
      ],
    },
    {
      header: `Which taxes did GST replace?`,
      body: [
        {
          content: `GST replaced all the taxes currently levied and collected by the
          centre (such as Central Excise Duty and CVD) and by the state (such
          as VAT and CST) on businesses.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `Is separate GST registration required for trading and manufacturing
        by the same company in one state?`,
      body: [
        {
          content: `There will be only one GST registration in one particular State for
          all business activities. But, there is the option to be registered
          as a separate entity vertical.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `If I register voluntarily though the turnover is less than 20 Lakhs,
      am I need to pay tax from 1st supply after post-registration?`,
      body: [
        {
          content: `Yes, you would be handled as a normal taxable person.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the time limit for taking a Registration under GST?`,
      body: [
        {
          content: `A person should take a Registration, within thirty days from the date on which he becomes liable to
          registration, in such manner and subject to such conditions as is prescribed under the Registration Rules. A Casual
          Taxable person and a non-resident taxable person should however apply for registration at least 5 days prior to
          commencement of business.`,
          icon: false,
        },
      ],
    },
    {
      header: `If a person is operating in different states, with the same PAN number, whether he can operate with a single Registration?`,
      body: [
        {
          content: `No. Every person who is liable to take a Registration will have to get registered separately
          for each of the States where he has a business operation and is liable to pay GST in terms of Section 22(1)
           of the CGST/SGST Act.`,
          icon: false,
        },
      ],
    },
    {
      header: `Whether a company having a SEZ unit or developer need to have separate registration?`,
      body: [
        {
          content: `Yes. As per the second proviso to sub-section 1of section 25 of the CGST Act, inserted vide the
          CGST (Amendment) Act, 2018, a person having SEZ unit or being SEZ developer shall have to apply for a separate
          registration, as distinct from his place of business located outside the SEZ in the same State or Union
          territory.`,
          icon: false,
        },
      ],
    },
    {
      header: `Whether the Registration granted to any person is permanent?`,
      body: [
        {
          content: `Yes, the registration Certificate once granted is permanent unless surrendered, cancelled, suspended or revoked.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the responsibility of the taxable person supplying to UN bodies?`,
      body: [
        {
          content: `The taxable supplier supplying to these organizations is expected to mention the UIN on the invoices
           and treat such supplies as supplies to another registered person (B2B) and the invoices of the same will be uploaded by the supplier.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Gst Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={regSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={regAboutData} />

          <Counter />
          <ScrollNav scroll_data={regScrollId} />

          <ImgContent item={regIcData} />
          <ContentForm CFSection_data={regCfData} />
          <SliderContent ScSection_data={regSCData} />
          <ContentImg CISection_data={regCiData} />
          <Cta />

          <Guidance />
          <FAQAccord items={GSTRegFAQ} />
          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
